.container {
    margin: 0 auto;
}

.heading {
    color: #D7CCC8;
    font-size: 24px;
    text-align: center;
    margin: 0;
}

.content {
    margin-top: 20px;
    align-self: center;
}

.paragraph {
    font-size: 23px;
    line-height: 1.25;
    margin-bottom: 10px;
}

.banner {
    background-color: #4E342E;
    padding: 10px;
    padding: 2em 0 2em 0;
    text-align: center;
    width: 100vw;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    margin-top: 2%;
}

.border {
    width: 60%;
    margin: 0 auto;
    height: 7px;
    border: 2px solid #D7CCC8;
    border-bottom: 0;
}

.banner h2 {
    margin: 0;
    font-size: 2em;
    color: #D7CCC8;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.30em;
    padding: 0.5em 0 0 0;
}

.banner p {
    color: #D7CCC8;
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0 0 0;
    line-height: 2em;
    letter-spacing: 2px;
    padding: 0 0 2em 0;
}

.border-bottom {
    border-top: 0;
    border-bottom: 2px solid #fff;
}

.office-card {
    width: 300px;
    padding: 10px;
    margin: 1rem;
    box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
    text-align: center;
    background-color: #4E342E;
    border-radius: 3px;
}

.officecard-title {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    color: #D7CCC8;
}

.officecard-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-color: #eceff1;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.officecard-data {
    font-size: 99%;
    color: #D7CCC8;
}

.image-resize {
    width: 100%;
    height: auto;
}

.card-row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.banner-text {
    align-self: center;
}

.alter-image {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.disp {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.img-child {
    width: 50vw;
    align-self: center;
}

@media screen and (max-width: 768px) {
    .office-card {
        max-width: 100%;
    }

    .img-child {
        width: 100vw;
    }
}