.navigation {
    background-color: #6D4C41;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.active-link {
    color: #FFF;
    background-color: #4E342E;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigation ul li {
    display: inline-block;
    margin-right: 10px;
}

.navigation ul li a {
    color: #D7CCC8;
    text-decoration: none;
    padding: 10px;
    font-size: 18px;
}

@media (min-width: 768px) {
    .navigation ul li a {
        font-size: 25px;
    }
}

.navigation ul li a:hover {
    color: #FFF;
    background-color: #4E342E;
    padding-top: 10px;
    padding-bottom: 10px;
}

.parent-component {
    position: relative;
    width: 100vw;
}

.child-component {
    position: relative;
    top: 60px;
}

@media (min-width: 768px) {
    .child-component {
        top: 70px;
    }
}


.logo-left {
    margin-left: 10px;
}

.logo-right {
    margin-right: 10px;
}


.image-resize {
    width: 300px;
    height: 250px;
}