.container {
    margin: 0 auto;
}

.heading {
    color: #D7CCC8;
}

.content {
    margin-top: 20px;
    margin-left: 2.5%;
    margin-right: 3%;
}

.paragraph {
    font-size: 23px;
    line-height: 1.25;
}

.banner {
    background-color: #4E342E;
    padding: 2em 0 2em 0;
    text-align: center;
    width: 100vw;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.banner h2 {
    margin: 0;
    font-size: 2em;
    color: #D7CCC8;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.30em;
    /* padding: 0.5em 0 0 0; */
}

.alter {
    width: 1000px;
    margin-top: 1%;
}

@media screen and (max-width: 768px) {
    .alter {
        width: 100vw;
    }
}