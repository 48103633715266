.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #6D4C41;
    position: fixed;
    left: 0;
    width: 100%;
    height: 30px;
    bottom: 0;
    color: #D7CCC8;
    font-size: 19px;
}

.footer-left {
    flex: 1;
    /* text-align: left; */
}

.footer-right {
    flex: 1;
    /* text-align: right; */
}

.motto-link {
    text-decoration: none;
    color: #D7CCC8;
}

.motto-link:hover {
    color: #FFF;
    background-color: #4E342E;
    padding-top: 10px;
    padding-bottom: 10px;
}