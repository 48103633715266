@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

.Past {
	text-align: center;
	margin-bottom: 30px;
}

.Past-cards {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.row {
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(255, 255, 255, 0.2);
	width: 95%;
	background: #4E342E;
	height: 95px;
	position: relative;
	margin: 1.5% 2.5% 0 2.5%;
}

.banner-heading {
	width: 100%;
	position: absolute;
	background: #F5DEB3;
	padding: 8px;
	line-height: 1em;
	top: 0px;
	font-size: 2em;
	display: block;
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	margin-left: -8px;
	box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

h1:before,
h1:after {
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	content: " ";
	transform: rotate(-45deg);
	z-index: -1;
	bottom: -6px;
}

h1:before {
	left: 3px;
	background: linear-gradient(135deg, rgba(245, 222, 179, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

h1:after {
	background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(245, 222, 179, 1) 100%);
	right: 2px;
}

.container {
    margin: 0 auto;
	margin-left: 2%;
	margin-right: 2%;
}

.content {
    margin-top: 20px;
}

.paragraph {
    font-size: 23px;
    line-height: 1.25;
    margin-bottom: 10px;
}

.deco {
	text-decoration: none;
}