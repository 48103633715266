.Articles {
	text-align: center;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.link-div {
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    width: 100vw;
}

.info-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 999;


    max-height: 80vh;
  overflow-y: auto;
}

.journal-card {
    width: 1100px;
    padding: 10px;
    margin: 1rem;
    box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
    text-align: center;
    background-color: #4E342E;
    border-radius: 3px;
    display: flex;
}

.child {
    flex: 1;
}

.jalter-image {
    width: 300px;
    height: 250px;
    margin-top: 1%;
}

.content {
    margin-top: 20px;
    align-self: center;
}

.jparagraph {
    font-size: 23px;
    line-height: 1.25;
    margin-bottom: 10px;
    color: #D7CCC8;
}

.jimage-container {
    width: 300px;
    padding: 10px;
    margin: 1rem;
    box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
    text-align: center;
    background-color: white;
    border-radius: 3px;

}

.Article p {
    font-size: 23px;
    line-height: 1.25;
    margin-bottom: 10px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
}