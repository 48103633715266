.road {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 53px;
    width: 100vw;
  }

  .road-image {
    width: 100vw;
  }