.carousel-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75vw;
    overflow: hidden;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: linear-gradient(to bottom right, #6f523a 0%, #c7b18e 50%, #af8f5c 100%);
    margin: 0 auto;
}

.slide {
    display: none;
    width: 100%;
    height: auto;
    max-width: 100%;
}

.slide.active {
    display: block;
}

.space {
    margin-bottom: 150px;
    margin-top: 20px;
}